import {request_async} from "@/utils/requestAsync";
import {API_URL} from "@/config/main";

// 获取一个
export async function getOne(data) {
    return request_async(API_URL + "/v1/courseRecord/", "get", data);
}

// 获取列表-分页
export async function getPageList(page, size, sort, data) {
    return request_async(API_URL + `/v1/courseRecord/pageList?page=${page}&size=${size}&sort=${sort}`, "post_body", data);
}

// 获取列表-不分页
export async function getList(data) {
    return request_async(API_URL + `/v1/courseRecord/list`, "post_body", data);
}

// 获取一个教学班的详情
export async function getOneTeachingClazzDetail(data) {
    return request_async(API_URL + "/v1/courseRecord/oneTeachingClazzDetail", "get", data);
}

// 获取教学班的学生记录列表-分页
export async function getTeachingClazzStudentList(page, size, sort, data) {
    return request_async(API_URL + `/v1/courseRecord/teachingClazzStudentList?page=${page}&size=${size}&sort=${sort}`, "post_body", data);
}

// 获取某个用户的记录列表-分页
export async function getOneUserRecordList(page, size, sort, data) {
    return request_async(API_URL + `/v1/courseRecord/oneUserRecordList?page=${page}&size=${size}&sort=${sort}`, "post_body", data);
}

// 获取某个用户的任务列表-分页
export async function getOneUserTaskList(page, size, sort, data) {
    return request_async(API_URL + `/v1/courseRecord/oneUserTaskList?page=${page}&size=${size}&sort=${sort}`, "post_body", data);
}


// 提交课程评价
export async function writeCourseComment(data) {
    return request_async(API_URL + `/v1/courseRecord/writeCourseComment`, "post_body", data);
}
