import {CommonModel} from "@/model/CommonModel";
import {
    getPageList,
    getList,
    getOne,
    writeCourseComment, getOneUserRecordList, getOneUserTaskList
} from "@/api/CourseRecordApi";

class CourseRecordModel {
    // 获取一个
    static async getOne(id) {
        let [res] = await getOne({
            courseTaskId: id
        })
        if (res.code === "000000") {
            return res.data
        } else {
            return false
        }
    }

    // 获取分页列表
    static async getPageList(page, size, sort, document) {
        let [data] = await getPageList(page, size, sort, document);
        return CommonModel.generateListMongo(data.data);
    }

    // 获取不分页列表
    static async getList(document) {
        let [data] = await getList(document);
        return data.data;
    }

    // 获取某个用户的课程记录
    static async getOneUserRecordList(page, size, sort, document) {
        let [data] = await getOneUserRecordList(page, size, sort, document);
        return CommonModel.generateListMongo(data.data);
    }

    // 获取某个用户的任务记录
    static async getOneUserTaskList(page, size, sort, document) {
        let [data] = await getOneUserTaskList(page, size, sort, document);
        return CommonModel.generateListMongo(data.data);
    }

    // 提交课程评价
    static async writeCourseComment(courseRecordId, content) {
        let [res] = await writeCourseComment({
            courseRecordId,
            content
        });
        return res.code === "000000";
    }
}

export {CourseRecordModel}
