// 通用枚举
export default {
    // 角色
    roles: {
        'teacher': '教师',
        "teacherSecretary": "教秘",
        "student": "学生"
    },
    // 课程类型分类
    courseType: {
        "网页版": "网页版",
        "客户端版": "客户端版"
    },
    // 帮助常见问题分类
    helpQuestionType: {
        "学生": "学生",
        "教师": "教师"
    },
    // 课程分数-结果
    courseScoreResult: {
        1: "完成",
        0: "未完成",
        2: "其他"
    },
    // 用户来源
    userFrom: {
        "us": "本平台",
        "iLab": "实训空间",
        "sczj": "四川职教平台"
    }
}
