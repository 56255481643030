
import {date_format} from "@/utils/common";
import enumsIndex from "@/enums/index"


/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// 日期格式化
export function dateFormat(value, format) {
  if (value) {
    let formatString = "yyyy-MM-dd HH:mm"
    if (format) {
      formatString = format
    }
    return date_format(value, formatString)
  }
  return ""
}

// 数字格式化
export function numberFormat(value, num) {
  if (value || value === 0) {
    let number = 0
    if (num) {
      number = num
    }
    return value.toFixed(number)
  }
  return ""
}

// 分数格式化
export function scoreFormat(value, num) {
  if (value || value === 0) {
    let number = 2
    if (num) {
      number = num
    }
    return value.toFixed(number)
  }
  return ""
}

// 字符串分行
export function stringBrFilter(value) {
  let arr = value.split("\n")
  let result = ""
  arr.forEach(li => {
    result += `<p>${li}</p>`
  })
  return result
}

// 课程实训分数用时格式化
export function scoreUseTimeFilter(value) {
  if (value < 60) {
    return Number(value).toFixed(1)+"秒"
  } else {
    let min = Math.floor(value / 60)
    let sec = Math.floor(value - min * 60)
    return `${min}分${sec}秒`
  }
}

// 角色名称格式化
export function rolesNameFilter(value) {
  let rolesName = []
  value.forEach(li => {
    rolesName.push(enumsIndex.roles[li])
  })
  return rolesName.join(",")
}
