<template>
  <el-dialog
      title="填写课程评价"
      :visible.async="show"
      width="980px"
      center
      @close="clickClose"
      :close-on-click-modal="false"
      v-el-drag-dialog>
    <div class="dialog-container">
      <h4>请填写您使用该虚拟仿真实训教学课程的真实感受（300字以内）</h4>
      <el-input type="textarea" v-model="content"></el-input>
    </div>
    <div slot="footer">
      <el-button type="primary" size="small" @click="clickSubmitCommentBtn">提交评价</el-button>
    </div>
  </el-dialog>
</template>

<script>
import elDragDialog from "@/directive/el-drag-dialog";
import {msg_err} from "../../utils/ele_component";
import {CourseRecordModel} from "../../model/CourseRecordModel";

export default {
  name: "writeComment",
  directives: {
    elDragDialog
  },
  props: {
    show: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    recordId: {
      type: String,
      default: () => {
        return ""
      }
    }
  },
  data() {
    return {
      content: ""
    }
  },
  methods: {
    clickClose() {
      this.$emit("clickClose", true)
    },
    // 点击提交评价按钮
    async clickSubmitCommentBtn() {
      // 判断字数
      if (this.content.length > 300) {
        msg_err("您填写的内容已超过300字，请修改后再提交！")
        return
      }
      if (this.content.length ===0) {
        msg_err("请先填写评价内容后再提交！")
        return
      }
      if (await CourseRecordModel.writeCourseComment(this.recordId, this.content).catch(res => {
        this.$emit("onSubmit", 'fail')
      })) {
        this.$emit("onSubmit", 'success')
      }
    },
  }
}
</script>

<style scoped>

</style>
