<template>
  <div class="page-userCourse">
    <div v-if="recordList.list.length>0">
      <div class="course-list">
        <div class="li flex flex-start" v-for="(item,index) in recordList.list">
          <div class="left-box">
            <img :src="item.courseEntity[0]['avatarUrl']" alt="" class="headImg">
          </div>
          <div class="right-box">
            <div class="name">
              <span class="name">{{ item.courseEntity[0]["name"] }}</span>
            </div>
            <div class="statistics-info flex flex-start">
              <div class="li">
                <span class="title">学习次数：</span>
                <span class="number">{{ item.courseScoreEntity.length }}次</span>
              </div>
              <div class="li">
                <span class="title">最高分：</span>
                <span class="number">{{ item.maxScore | scoreFormat }}分</span>
              </div>
              <div class="li">
                <span class="title">最低分：</span>
                <span class="number">{{ item.minScore | scoreFormat }}分</span>
              </div>
              <div class="li">
                <span class="title">平均分：</span>
                <span class="number">{{ item.averageScore | scoreFormat }}分</span>
              </div>
            </div>
            <div class="buttons flex flex-start">
              <el-button size="small" type="default" @click="clickEnterCourseBtn(item)">进入课程</el-button>
              <el-button size="small" type="default" @click="clickShowScoreBtn(item,index)">我的成绩</el-button>
            </div>
          </div>
        </div>
      </div>
      <!--列表分页-->
      <div class="pagination-container">
        <el-pagination background @current-change="(number)=>pageChange(number)"
                       :current-page.sync="recordList.pages.number" :page-size.sync="recordList.pages.size"
                       layout="total,prev, pager, next,jumper,sizes" :total="recordList.pages.totalElements"
                       @size-change="(size)=>pageLimitChange(size)"
                       :page-count="recordList.pages.totalPages">
        </el-pagination>
      </div>
    </div>
    <el-empty description="暂无数据" v-if="recordList.list.length===0"></el-empty>
    <!--成绩列表弹窗-->
    <el-dialog
        title="成绩列表"
        :visible.sync="scoreList.dialog"
        width="980px"
        center
        :close-on-click-modal="false"
    >
      <div class="dialog-container">
        <el-descriptions size="medium" style="margin-bottom: 0px" v-if="userInfo.role==='student'">
          <el-descriptions-item label="账号">{{ userInfo.account }}</el-descriptions-item>
          <el-descriptions-item label="姓名">{{ userInfo.name }}</el-descriptions-item>
        </el-descriptions>
        <el-table :data="scoreList.list" v-loading="scoreList.loading"
                  element-loading-text="加载中" fit border max-height="500px"
                  style="width: 100%;">
          <el-table-column label="课程名称" prop="courseName" align="center">
            <template slot-scope="scope">
              <span>{{ scoreList.course.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实训结果" prop="courseName" align="center" width="80px">
            <template slot-scope="scope">
              <span>{{ enums.courseScoreResult[scope.row.result] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实训成绩" prop="courseName" align="center" width="80px">
            <template slot-scope="scope">
              <span>{{ scope.row.score | scoreFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实训开始时间" prop="courseName" align="center" width="170px">
            <template slot-scope="scope">
              <span>{{ scope.row.startTime | dateFormat("yyyy-MM-dd HH:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实训结束时间" prop="courseName" align="center" width="170px">
            <template slot-scope="scope">
              <span>{{ scope.row.endTime | dateFormat("yyyy-MM-dd HH:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实训用时" prop="courseName" align="center" width="80px">
            <template slot-scope="scope">
              <span>{{ scope.row.usedTime | scoreUseTimeFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="courseName" align="center" width="80px">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="clickShowScoreDetailBtn(scope.row,scope.$index)">查看详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!--成绩详情弹窗-->
    <el-dialog
        :title="scoreInfo.title"
        :visible.sync="scoreInfo.dialog"
        :close-on-click-modal="true"
        :append-to-body="true"
        width="1200px"
        center
    >
      <div class="dialog-container dialog-scoreInfo">
        <div class="info flex flex-start flex flex-wrap">
          <div class="li name">
            <span class="title">实训名称：</span>
            <span class="text">{{ scoreList.course.name }}</span>
          </div>
          <div class="li">
            <span class="title">实训开始时间：</span>
            <span class="text">{{ scoreInfo.info.startTime |dateFormat("yyyy-MM-dd HH:mm:ss") }}</span>
          </div>
          <div class="li">
            <span class="title">实训结束时间：</span>
            <span class="text">{{ scoreInfo.info.endTime |dateFormat("yyyy-MM-dd HH:mm:ss") }}</span>
          </div>
          <div class="li">
            <span class="title">实训用时：</span>
            <span class="text">{{ scoreInfo.info.usedTime | scoreUseTimeFilter }}</span>
          </div>
          <div class="li">
            <span class="title">实训满分：</span>
            <span class="text">{{ scoreInfo.info.fullScore | scoreFormat }}</span>
          </div>
          <div class="li">
            <span class="title">实训得分：</span>
            <span class="text">{{ scoreInfo.info.score |scoreFormat }}</span>
          </div>
          <div class="li">
            <span class="title">实训结果：</span>
            <span class="text">{{ enums.courseScoreResult[scoreInfo.info.result] }}</span>
          </div>
        </div>
        <div class="list">
          <el-table :data="scoreInfo.info.stepInfo" v-loading="scoreInfo.loading"
                    element-loading-text="加载中" fit border max-height="400px"
                    style="width: 100%;">
            <el-table-column label="步骤名称" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否完成" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.done ? "完成" : "未完成" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="步骤开始时间" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.startTime |dateFormat("yyyy-MM-dd HH:mm:ss") }}</span>
              </template>
            </el-table-column>
            <el-table-column label="步骤结束时间" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.endTime |dateFormat("yyyy-MM-dd HH:mm:ss") }}</span>
              </template>
            </el-table-column>
            <el-table-column label="步骤用时(秒)" align="center" width="80px">
              <template slot-scope="scope">
                <span>{{ scope.row.timeUsed  | scoreUseTimeFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column label="步骤合理用时" align="center" width="80px">
              <template slot-scope="scope">
                <span>{{ scope.row.expectTime  | scoreUseTimeFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column label="步骤满分" align="center" width="80px">
              <template slot-scope="scope">
                <span>{{ scope.row.maxScore | scoreFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="步骤得分" align="center" width="80px">
              <template slot-scope="scope">
                <span>{{ scope.row.score | scoreFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column label="步骤操作次数" align="center" width="80px">
              <template slot-scope="scope">
                <span>{{ scope.row.repeatCount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="步骤评价" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.evaluation }}</span>
              </template>
            </el-table-column>
            <el-table-column label="赋分模型" align="center">
              <template slot-scope="scope">
                <div>
                  <div class="text" v-for="item in scope.row.scoringModel.split('\n')">{{ item }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center">
              <template slot-scope="scope">
                <div>
                  <div class="text" v-for="item in scope.row.remarks.split('\n')">{{ item }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!--填写课程评价弹窗-->
    <write-comment :show="writeComment.dialog" @clickClose="writeComment.dialog=false"
                   :record-id="writeComment.recordId"
                   @onSubmit="onCommentSubmit"></write-comment>
  </div>
</template>

<script>
import elDragDialog from "@/directive/el-drag-dialog";
import writeComment from "../components/writeComment";
import {mapState} from "vuex";
import {CourseRecordModel} from "../../model/CourseRecordModel";
import {calAverageNumber} from "../../utils/common";
import {dateFormat, scoreFormat, scoreUseTimeFilter} from "../../filters";
import {msg_err, msg_success} from "../../utils/ele_component";
import enums from "../../enums/index"
import {UserModel} from "../../model/UserModel";

export default {
  name: "UserCourse",
  components: {writeComment},
  directives: {
    elDragDialog
  },
  filters: {
    scoreFormat,
    dateFormat,
    scoreUseTimeFilter
  },
  computed: {
    ...mapState({
      webConfig: state => state.webConfig,
      userInfo: state => state.userInfo,
      courseId: state => state.courseId,
    })
  },
  data() {
    return {
      enums: enums,
      window: window,
      recordList: {
        list: [],
        loading: false,
        query: {},
        queryBase: {},
        sort: "",
        pages: {
          size: 10
        },
        searchFilter: {
          search: [],
          filter: [],
        }
      },
      scoreList: {
        dialog: false,
        list: [],
        listLoading: false,
        course: {}
      },
      scoreInfo: {
        title: "成绩详情",
        dialog: false,
        info: {
          stepInfo: []
        },
        listLoading: false,
      },
      writeComment: {
        dialog: false,
        content: ""
      },
    }
  },
  async mounted() {
    this.getList(0, 10, {})
  },
  methods: {
    // 获取列表
    async getList(page, size, query) {
      if (this.userInfo.account === "expert") {// 如果是专家用户，就只能看到当前实训记录
        query = Object.assign({
          courseId: this.courseId
        })
      }
      this.recordList.loading = true;
      let list = [];
      [list, this.recordList.pages] = await CourseRecordModel.getOneUserRecordList(page - 1, size, "", query)
      // 遍历列表计算分数
      for (let i = 0; i < list.length; i++) {
        let li = list[i]
        let scoreList = li.courseScoreEntity
        let scoreArr = scoreList.map(li => {
          return li.score
        })
        scoreList.sort((a, b) => {
          return b.createTime - a.createTime
        })
        li.courseScoreEntity = scoreList
        if (scoreArr.length > 0) {
          // todo 在一次计算中完成
          li.maxScore = Math.max(...scoreArr)
          li.minScore = Math.min(...scoreArr)
          li.averageScore = calAverageNumber(scoreArr)
        } else {
          li.maxScore = 0.0
          li.minScore = 0.0
          li.averageScore = 0.0
        }

      }
      this.$set(this.recordList, "list", list)
      this.recordList.loading = false
    },
    // 分页-改变页码
    async pageChange(page) {
      this.getList(page, this.recordList.pages.size, this.recordList.query)
    },
    // 分页-改变每页显示数量
    async pageLimitChange(size) {
      this.getList(this.recordList.pages.number - 1, size, this.recordList.query)
    },
    // 点击搜索按钮
    clickSearchFilterBtn(query) {
      this.recordList.query = query
      this.getList(0, this.recordList.pages.size, this.recordList.query)
    },
    // 点击进入课程按钮
    clickEnterCourseBtn(record) {
      let course = record.courseEntity[0]
      this.$router.push({
        name: "CourseInfo",
        params: {
          "coursePath": course.coursePath
        }
      })
    },
    // 点击课程评价按钮
    clickOpenCommentBtn(record, index) {
      if (record.hasWriteComment) {
        msg_err("您已经评价过该课程啦！")
        return
      }
      if (!record.courseCompleted) {
        msg_err("请先至少完成一次课程后再评价！")
        return
      }
      this.writeComment.dialog = true
      this.writeComment.recordId = record.courseRecordId
      this.writeComment.index = index
    },
    // 当课程评价提交
    async onCommentSubmit(result) {
      if (result === "success") {
        msg_success("提交评价成功！")
        this.writeComment.dialog = false
        this.$set(this.recordList.list[this.writeComment.index], "hasWriteComment", true)
        await UserModel.getInfoByToken()
      }
      if (result === "fail") {
        msg_success("提交评价失败！")
        this.writeComment.dialog = false
      }
    },
    // 点击我的成绩按钮
    clickShowScoreBtn(record, index) {
      this.$set(this.scoreList, "list", record.courseScoreEntity)
      this.$set(this.scoreList, "course", record.courseEntity[0])
      this.scoreList.dialog = true
    },
    // 点击成绩详情按钮
    clickShowScoreDetailBtn(score, index) {
      this.scoreInfo.dialog = true
      this.$set(this.scoreInfo, "info", score)
      this.$set(this.scoreInfo, "index", index)
    }
  }
}
</script>

<style scoped lang="less">
@import '../../style/app.less';

.page-userCourse {

}

.course-list {
  > .li {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0px;
    }

    .left-box {
      width: 250px;

      img.headImg {
        width: 230px;
        height: 140px;
      }
    }

    .right-box {
      width: 680px;

      div.name {
        span.name {
          font-size: 17px;
          margin-bottom: 20px;
          font-weight: bold;
        }
      }

      div.statistics-info {
        margin-top: 35px;
        margin-bottom: 35px;

        > .li {
          margin-right: 20px;
          font-size: 14px;
          color: #666;

          .title {

          }

          .number {
            color: #25a170;
          }
        }
      }


      div.buttons {
        .el-button {
          margin-right: 22px;
        }
      }
    }
  }
}

.dialog-scoreInfo {
  .info {
    .li {
      margin-bottom: 10px;
      width: 33%;

      .title {
        font-size: 14px;
        color: #666;
      }

      .text {
        font-size: 14px;
        color: #999;
      }
    }

    .li.name {
      width: 100%;
    }
  }
}

</style>
